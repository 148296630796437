var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end align-items-center",
    attrs: {
      "lg": "8"
    }
  }, [_vm.$permissionAbility(_vm.DEPARTMENT_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create Department ")])] : _vm._e()], 2)], 1), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "style-class": "vgt-table striped"
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row5$head, _props$column, _props$row6, _props$row7, _props$row8;
        return [_vm.$permissionAbility(_vm.DEPARTMENT_SHOW, _vm.permissions) ? [props.column.field === 'format_name' ? _c('span', [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.name))])], 1) : _vm._e()] : [props.column.field === 'format_name' ? _c('span', [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.name) + " ")])]) : _vm._e()], props.column.field === 'format_users_count' ? _c('span', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.users_count) + " ")])], 1) : _vm._e(), props.column.field === 'head_user' ? _c('span', [(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.head_id) != null ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$head = _props$row5.head) === null || _props$row5$head === void 0 ? void 0 : _props$row5$head.data
          }
        })] : _vm._e()], 2) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_member_info' ? [_vm._l(props.row.users.data, function (member, index) {
          return _c('span', {
            key: index
          }, [index <= 2 ? [_c('b-avatar', {
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
              "size": "26"
            }
          })] : _vm._e()], 2);
        }), (props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.users_count) > 3 ? _c('span', [_vm._v(" + " + _vm._s((props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.users_count) - 3) + " more ")]) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.users_count) > 0 ? _c('b-button', {
          staticClass: "btn-icon rounded-circle",
          staticStyle: {
            "margin-left": "5px"
          },
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row9, _props$row9$users;
              return _vm.showAllAddedMemberModal(props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : (_props$row9$users = _props$row9.users) === null || _props$row9$users === void 0 ? void 0 : _props$row9$users.data);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UsersIcon"
          }
        })], 1) : _c('span', [_vm._v("N/A")])] : props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.DEPARTMENT_SHOW, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "EyeIcon",
            "title": "View",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.DEPARTMENT_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.DEPARTMENT_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "modal-department-form",
      "centered": "",
      "title": _vm.modelType === 'editModel' ? 'Edit' : 'Create',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "departmentValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Department"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Head",
      "label-for": "head-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "head_id",
      "vid": "head_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "head-id",
            "placeholder": "Choose a Head",
            "options": _vm.headIdOption,
            "reduce": function reduce(country) {
              return country.id;
            },
            "label": "name"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                staticClass: "mr-1",
                attrs: {
                  "src": (data === null || data === void 0 ? void 0 : data.avatar) === '' ? '/avatar.svg' : data === null || data === void 0 ? void 0 : data.avatar
                }
              }), _c('div', [_c('div', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.name) + " "), _c('b-badge', {
                attrs: {
                  "pill": "",
                  "variant": "warning"
                }
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.employee_number))])], 1), _c('div', {
                staticClass: "font-small-2"
              }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.email) + " ")])])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.selectHeadId,
            callback: function callback($$v) {
              _vm.selectHeadId = $$v;
            },
            expression: "selectHeadId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Division",
      "label-for": "division-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "division_id",
      "vid": "division_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Choose a Division",
            "options": _vm.divisionIdOption,
            "reduce": function reduce(country) {
              return country.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectDivisionId,
            callback: function callback($$v) {
              _vm.selectDivisionId = $$v;
            },
            expression: "selectDivisionId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isDepartmentFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-all-added-member",
      "centered": "",
      "title": "Assigned Employee",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAllAddedMemberModal
    }
  }, [_c('ul', _vm._l(_vm.assingedMember, function (member, index) {
    return _c('li', {
      key: index
    }, [_c('b-avatar', {
      staticStyle: {
        "margin": "1px"
      },
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    }), _vm._v(" " + _vm._s(member.name) + " ")], 1);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }